import * as React from "react";

export const Spinner = ({ className }) => {
    return (
        <div className={`spinner m-auto ${className}`}>
            <div className="rect1"></div>
            <div className="rect2"></div>
            <div className="rect3"></div>
            <div className="rect4"></div>
            <div className="rect5"></div>
        </div>
    );
};
