import { Dashboard } from "../dashboard/dashboard";

export default Dashboard;

/*
 `src/dashboard` is not "special", it is re-exported by `src/pages/dashboard.js`
 and contains all the client-side dynamic Dashboard pages that we dont want to be statically generated.
 `src/pages/dashboard.js` skips the static generation process because of `gatsby-plugin-create-client-paths`
 configured in `gatsby-config.js`
 */
